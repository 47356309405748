import React from "react"
import Title from "./Title"
import Project from "./Project"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const Projects = ({ projects, title, showLink }) => {
  const { allFile: { edges } } = useStaticQuery(graphql`
  {
    allFile {
      edges {
        node {
          name
          childrenImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`)
  // let projects = edges.filter(project => project.node.childrenImageSharp.length > 0)
  // edges.map(project => {
  //   console.log(project.node.childrenImageSharp.length)
  // })
  // console.log(edges)
  // console.log(projects)
  return (
    <section className="section projects">
      <Title title={title} />
      <div className="section-center projects-center">
        {projects.map((project, index) => {
          return <Project key={project.id} index={index} {...project} imagesArray={edges} />
        })}
      </div>
      {showLink && (
        <Link to="/projects" className="btn center-btn">
          projects
        </Link>
      )}
    </section>
  )
}

export default Projects