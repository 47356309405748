const imagePath = "../assets/images/";

export default [
    {
        id: 8,
        title: "Calendaire",
        slug: "calendaire",
        description: `Calendaire is a user-friendly scheduling platform that simplifies your appointment management. Built with Next.js and PostgreSQL, it features seamless integration with Google Meet and Google Calendar, making it easy to schedule meetings and manage your time efficiently.`,
        github: "https://github.com/Abhishek-Shukla-github/calendaire",
        url: "https://calendaireab.vercel.app/",
        stack: [
            {
                id: 1,
                title: "TypeScript"
            },
            {
                id: 2,
                title: "NextJS"
            },
            {
                id: 3,
                title: "PostgreSQL"
            },
            {
                id: 4,
                title: "Google Meet"
            },
            {
                id: 5,
                title: "Google Calendar"
            },
            {
                id: 6,
                title: "Tailwind CSS"
            },
            {
                id: 7,
                title: "Prisma"
            },
            {
                id: 5,
                title: "React Hook form"
            },
        ],
        image: "calendaire.png"
    },
    {
        id: 9,
        title: "Maplemart",
        slug: "maplemart",
        description: `Maplemart is a fully functional eCommerce platform built using React, TypeScript, MongoDB, Express, and Node.js with Redux Toolkit for state management. It features a full-featured shopping cart, product reviews, an admin panel, and integration with PayPal for payment processing.`,
        github: "https://github.com/Abhishek-Shukla-github/Maplemart",
        url: "https://maplemart.onrender.com", // Update this with the actual live URL
        stack: [
            {
                id: 1,
                title: "React"
            },
            {
                id: 2,
                title: "TypeScript"
            },
            {
                id: 3,
                title: "MongoDB"
            },
            {
                id: 4,
                title: "Express"
            },
            {
                id: 5,
                title: "Node.js"
            },
            {
                id: 6,
                title: "Redux Toolkit"
            },
            {
                id: 7,
                title: "JWT"
            },
            {
                id: 6,
                title: "Vite"
            },
        ],
        image: "maplemart.png" // Update this with the actual image file name
    },
    {
        id: 6,
        title: "Data-Gauge",
        slug: "data-gauge",
        description: `Data Gauge is a Dashboard app developed using React and MUI theme. It includes a variety of charts such as bar graphs, line graphs, and pie charts. The app also incorporates a Chloropleth Map and a sophisticated Calendar component that allows event registration, shifting, editing, and deletion. Vite is utilized to generate the app, providing improved control over chunking and bundling. The app is code-split, resulting in fast performance even with resource-intensive packages. Additionally, the app makes multiple API calls using MirageJS and implements lazy-loading for the Transactions API on the Home Page.`,
        github: "https://github.com/Abhishek-Shukla-github/Data-gauge",
        url: "https://data-gauge.netlify.app/",
        stack: [
            {
                id: 1,
                title: "HTML"
            },
            {
                id: 2,
                title: "CSS"
            },
            {
                id: 3,
                title: "ReactJS"
            },
            {
                id: 4,
                title: "Material-UI"
            },
            {
                id: 4,
                title: "MUI Theme"
            },
            {
                id: 5,
                title: "Vite"
            },
            {
                id: 6,
                title: "ContextAPI"
            },
            {
                id: 7,
                title: "Lazy-Loading"
            },
            {
                id: 8,
                title: "Code-Splitting"
            },
            {
                id: 9,
                title: "MirageJS"
            },
        ],
        image: "data-gauge.jpg"
    }, 
    {
        id: 1,
        title: "Colorama",
        slug: "colorama",
        description: `Colorama is the ultimate portal on the web for all your color queries. Colorama is a color palette maker app, which lets user to create their own color, create their own palette, choose from variety of premade palettes and use it in their projects. It provides 9 different shades for every single color, which user can use with a click with help copy-to-clipboard animation. All colors are available in 3 formats.`,
        github: "https://github.com/Abhishek-Shukla-github/Colorama",
        url: "https://colorama.netlify.app/",
        stack: [
            {
                id: 1,
                title: "HTML"
            },
            {
                id: 2,
                title: "CSS"
            },
            {
                id: 3,
                title: "ReactJS"
            },
            {
                id: 4,
                title: "Material-UI"
            },
        ],
        image: "colorama.jpg"
    },
    {
        id: 3,
        title: "web-generate",
        slug: "web-generate",
        // description: `web-generate command line utility to generate HTML, CSS and JS files which are required to start a web developement project, on top that it link the CSS and JS files out of the box, so in a single command we're ready to start coding in no time.`,
        description: `Introducing "WebGen," a powerful command line utility designed to supercharge your web development projects! With just a single command, WebGen swiftly generates HTML, CSS, and JS files, getting you ready to code in no time. But that's not all—WebGen goes the extra mile by automatically linking the CSS and JS files for you. Say goodbye to tedious setup and hello to instant productivity. Get started with WebGen and unleash your web development potential!`,
        github: "https://github.com/Abhishek-Shukla-github/web-generate",
        url: "https://www.npmjs.com/package/web-generate",
        stack: [
            {
                id: 1,
                title: "HTML"
            },
            {
                id: 2,
                title: "CSS"
            },
            {
                id: 3,
                title: "ReactJS"
            },
            {
                id: 4,
                title: "Material-UI"
            },
            {
                id: 5,
                title: "react-speech-kit"
            },
        ],
        image: "web-generate.jpg"
    },
    {
        id: 4,
        title: "react-searchify",
        slug: "react-searchify",
        // description: `react-searchify is a modular NPM-package to integrate a search-navigation support to a React Application. It provides a modular way to integrate a search navigation support by passing the necessary props.
        // All of the pages of the project can be covered and accessed with a single click. It provides out of the box Voice support enabled which can be used optionally`,
        description: `
        Introducing "React-Searchify," a game-changing NPM package for seamless search-navigation integration in React applications. With its modular design, React-Searchify allows you to effortlessly incorporate search navigation support by simply passing the required props. Say goodbye to complex setups and hello to streamlined accessibility. With React-Searchify, you can easily cover and access all project pages with just a single click. And that's not all! It even comes with built-in voice support, giving you the option to enable an immersive user experience. Unlock the power of React-Searchify and elevate your React application to new heights!`,
        github: "https://github.com/Abhishek-Shukla-github/react-searchify",
        url: "https://www.npmjs.com/package/react-searchify",
        stack: [
            {
                id: 1,
                title: "JavaScript"
            },

            {
                id: 2,
                title: "NodeJS"
            },
            {
                id: 3,
                title: "ReactJS"
            },
            {
                id: 4,
                title: "react-speech-synthesis"
            },
        ],
        image: "react-searchify.jpg"
    },
    {
        id: 5,
        title: "Domus",
        slug: "Domus",
        description: "Domus is a house rental and selling marketplace wherein sellers or landlords can list their apartments or properties for rent or sell , add in the required informatins and based on this users can easily search for a property and can then directly contact the landlord or owner to carry out the further transaction",
        github: "https://github.com/Abhishek-Shukla-github/domus",
        url: "https://domus.vercel.app/",
        stack: [
            {
                id: 1,
                title: "JavaScript"
            },
            {
                id: 3,
                title: "ReactJS"
            },
            {
                id: 4,
                title: "Material UI"
            },
            {
                id: 5,
                title: "Open Street Map"
            },
            {
                id: 6,
                title: "vercel"
            },
            {
                id: 2,
                title: "Firebase"
            },
            {
                id: 7,
                title: "firebase-auth"
            },
        ],
        image: "react-searchify.jpg"
    },
    
    {
        id: 7,
        title: "Fronta",
        slug: "fronta",
        description: `
        Fronta is a static blog site created using NextJS and Tailwind CSS. It uses a marker package to parse and display markdown files exported from Hashnode via GitHub. The blog incorporates a custom script that generates a cached folder of blog posts during commits, enhancing rendering efficiency when deployed on Vercel.`,
        github: "https://github.com/Abhishek-Shukla-github/Fronta",
        url: "https://fronta.vercel.app/",
        stack: [
            {
                id: 1,
                title: "HTML"
            },
            {
                id: 2,
                title: "CSS"
            },
            {
                id: 3,
                title: "ReactJS"
            },
            {
                id: 4,
                title: "NextJS"
            },
            {
                id: 4,
                title: "Tailwind CSS"
            },
            {
                id: 5,
                title: "Vercel"
            },
            {
                id: 6,
                title: "Markdown"
            },
            {
                id: 7,
                title: "marker"
            }
        ],
        image: "fronta.jpg"
    },
    {
        id: 2,
        title: "Vox Imperium",
        slug: "vox-imperium",
        description: `Vox Imperium is a voice controlled news reader build with React and Material UI which fetches news from variety of sources, categories, keywords, recent headlines on a single click and audio command. The app then can read all the fetched news and implement auto-scroll feature while reading thereby mimicking a human-like behaviour.`,
        github: "https://github.com/Abhishek-Shukla-github/vox-imperium",
        url: "https://vox-imperium.netlify.app/",
        stack: [
            {
                id: 1,
                title: "JavaScript"
            },

            {
                id: 2,
                title: "NodeJS"
            }
        ],
        image: "web-generate.jpg"
    },
]