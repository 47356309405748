import React from "react"
import { Link } from "gatsby"
import socialLinks from "../constants/social_links"
// import heroImg from "../assets/images/hero.svg"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>i'm Abhishek</h1>
            <h3>Software Engineer | CS Graduate </h3>
            <h4>M.Eng in ISS at Concordia University | Montreal</h4>
            <h4>building the next-gen software systems</h4>
            {/* <h3>Information Systems Security at</h3> */}
            {/* <h4>applying the CS principles</h4> */}
            <Link to="/contact" className="btn">
              contact me
            </Link>
            <div className="social-links">
              {socialLinks.map(link => {
                return (
                  <a className="social-link" href={link.url} key={link.id} target="_blank">
                    {link.icon}
                  </a>
                )
              })}
            </div>
            {/* <SocialLinks /> */}

          </div>
        </article>
        {/* <Image fluid={fluid} className="hero-img" /> */}
        <StaticImage
          src="../assets/images/avatar.png"
          alt="Abhishek Shukla"
          className="hero-img"
          placeholder="blurred"
        />
      </div>
    </header>
  )
}

export default Hero
