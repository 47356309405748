export default

    [
        {
            "id": 3,
            "company": "ITILITE Technologies",
            "position": "Software Engineer",
            "date": "January 2022 - May 2023",
            "desc": [
                {
                    "id": 1,
                    // "point": "Engineered and delivered an end-to-end project module called “Mileage Calculator” wherein finance admins can configure mileage rates for vehicles and based on that employees can file a mileage expense reimbursement which can then be approved and reimbursed to the employee’s account."
                    "point": "Code-Splitting and Lazy-Loading: I successfully implemented end-to-end code-splitting and lazy-loading techniques for React components on the platform. By minifying CSS and JS files and optimizing chunk sizes, I reduced the build size from 1.46MB to smaller chunks under 10KB. This optimization resulted in a remarkable improvement in the app's load speed, reducing it from 9 seconds to under 500ms—an impressive 18 times faster load speed."
                },
                {
                    "id": 2,
                    "point": "Customizable Export Template Functionality: I developed a versatile Export Template functionality in React, empowering admins to customize column selections and order through intuitive drag-and-drop functionality. Admins can conveniently preview or download the template as a spreadsheet, eliminating the need for backend involvement. This self-serve feature saved over 9 days per month that were previously spent on creating export templates to meet client requirements."
                },
                {
                    "id": 3,
                    "point": "Salesforce Einstein Chatbot Integration: I successfully integrated the Salesforce Einstein Chatbot with the Expense Platform, providing users with an intelligent and efficient support system. This integration enhanced user interactions and facilitated quick access to relevant information."
                },
                {
                    "id": 4,
                    "point": "Bulk Payment and Dynamic Filters: I implemented a robust Bulk Payment functionality that allowed Finance admins to efficiently select rows of reimbursable expenses and process them in bulk. Additionally, I developed Dynamic Filters for Payout tables, enabling admins to filter expenses based on various dynamic criteria. These features significantly accelerated the processing speed of expense reports and payout reimbursements."
                },
                // {
                //     "id": 5,
                //     "point": `Implemented Bulk Payment functionality, enabling Finance admins to efficiently select rows of reimbursable expenses and process them in bulk. Developed Dynamic Filters functionality for Payout tables, allowing finance admins to filter expenses based on various dynamic filters. This significantly increased the processing speed of expense reports and payout reimbursements`
                // },
                {
                    "id": 5,
                    "point": `
                    Seamless Expense Navigation: I implemented a seamless Expense Navigation feature on the Expense Platform, providing users with an intuitive and smooth experience while navigating between expenses within a report. Leveraging Redux and background API fetching, I engineered a feature that mimics the effortless sliding between photos. This innovative functionality received special praise from JCDecaux's Finance Admin, showcasing its value in improving efficiency and user satisfaction.`
                },
                {
                    "id": 6,
                    "point": `Recognized as The Value Champion in JFM '22 quarter for demonstrating exceptional impact, contribution and ownership.`
                },
            ]
        },
        {
            "id": 2,
            "company": "ITILITE Technologies",
            "position": "Frontend Engineering Intern",
            "date": "September 2021 - December 2021",
            "desc": [
                {
                    "id": 1,
                    "point": "During my tenure at ITILITE, I contributed significantly to the Expense Management Platform"
                },
                {
                    "id": 2,
                    "point": "Expense Styling Migration: I played a pivotal role in migrating the entire expense project's styling from CSS to SCSS. This transition not only enhanced the codebase's maintainability but also improved the development workflow for the team, unlocking the advantages of SCSS."
                },
                // {
                //     "id": 3,
                //     "point": "Engineered and delivered an end-to-end project module called “Mileage Calculator” using  MUI and integrated backend APIs and employed efficient state management through Redux, wherein finance admins can configure mileage rates for vehicles and based on that employees can file a mileage expense reimbursement which can then be approved and reimbursed to the employee’s account."
                // },
                {
                    "id": 3,
                    "point": "Mileage Calculator Module: I engineered and successfully delivered an end-to-end project module known as the Mileage Calculator. Utilizing MUI (Material-UI) components, I integrated backend APIs and employed efficient state management through Redux. This module allowed finance administrators to configure mileage rates for different vehicle types. Employees could then file mileage expense reimbursements based on these rates. The system facilitated the approval and reimbursement of expenses directly to the employee's account."
                },
                // {
                //     "id": 4,
                //     "point": "The highlight of this module was the integration of Google Maps, enabling users to select up to 8 locations, rearrange waypoints using drag-and-drop and then calculate the shortest path and then depending on the mileage rate configured don the backend for a particular vehicle type, the mileage expense would be calculated."
                // }
                {
                    "id": 4,
                    "point": "Google Maps Integration: A noteworthy highlight of the Mileage Calculator module was the seamless integration of Google Maps. This feature empowered users to select up to eight locations, rearrange waypoints using drag-and-drop functionality, and calculate the shortest path. By leveraging the backend-configured mileage rates for specific vehicle types, the module accurately calculated mileage expenses."
                },
                {
                    "id": 5,
                    "point": `Recognized as "The Rookie of the quarter", awarded to the best intern, for my exceptional performance also leading to a full-time opportunity with the organization.`
                }
            ]
        },
        {
            "id": 1,
            "company": "Hannovit Technologies",
            "position": "Full Stack Developer Intern",
            "date": "January 2021 - July 2021",
            "desc": [
                {
                    "id": 1,
                    "point": "Codebase Transformation: I successfully migrated a React codebase from class-based components to modern Functional Components, optimizing the code for efficiency and maintainability."
                },
                {
                    "id": 2,
                    "point": "Dashboard Creation: Using D3 and react-charts, I developed a visually appealing dashboard featuring charts, graphs, and comprehensive statistical denotations. This provided users with valuable insights and enhanced their overall experience."
                },
                {
                    "id": 3,
                    "point": "Patient Management System: I implemented end-to-end patient management and booking functionality using ReactJS and Django. This included a complex calendar feature tailored for doctors to create, view, edit, and delete patient appointments."
                },
                {
                    "id": 6,
                    "point": "Component Development: Developed various Components in React for CRM product for dentists and chiropractors who are companies' client based in Washington."
                },
                {
                    "id": 7,
                    "point": "3rd Party API Integration : Integrated call-rails API to UI via MP3 audio player and React Bottomsheet Component."
                },
                {
                    "id": 8,
                    "point": "Form Design and Development: Designed and developed modular 2 step React forms."
                },
                {
                    "id": 4,
                    "point": "Offer Flow API: Leveraging Django and PostgreSQL, I designed and implemented an API that enabled shopkeepers to customize and apply limited-time offers to their products on an e-commerce project based in Hannover."
                },
                {
                    "id": 5,
                    "point": "Open Street Map Integration: I seamlessly integrated Open Street Map into the project, allowing automatic extraction of shop locations and rendering them on the shop's details page."
                },
            ]
        }
    ]