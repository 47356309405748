import React from "react"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
import projects from "../data/projects";
const IndexPage = () => {
  return (
    <>
      <Seo title="Home" />
      <Hero />
      {/* <Services /> */}
      <Jobs />
      <Projects title="projects" projects={projects} showLink={false} />
    </>
  )
}

export default IndexPage
