import React from "react"
// import PropTypes from "prop-types"
// import Image from "gatsby-image"
import { FaGithubSquare, FaShareSquare, FaNpm } from "react-icons/fa"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Project = ({ description, title, github, stack, url, image, index, imagesArray }) => {
  // console.log(imagesArray[index + 3].node.childrenImageSharp[0].gatsbyImageData)
  let localImage = {}
  imagesArray.forEach(image => {
    if (image.node.name.toLowerCase() === title.toLowerCase()) {
      console.log(image.node.name.toLowerCase(), title.toLowerCase())
      // console.log(image.node.childrenImageSharp[0].gatsbyImageData)
      localImage = image.node.childrenImageSharp[0].gatsbyImageData
    }
  })
  // console.log("localImage",localImage, "image",image)
  return (
    <article className="project">
      {image && Object.keys(localImage)?.length && (
        // <Image fluid={true} className="project-img" />
        // <StaticImage
        //   src={`../assets/images/projects-1.jpg`}
        //   alt={title}
        //   className="project-img"
        //   placeholder="blurred"
        // />
        <GatsbyImage image={localImage} className="project-img" alt={title} placeholder="blurred" />
        // <h1>{title}</h1>
      )}
      <div className="project-info">
        <span className="project-number">0{index + 1}.</span>
        <Link to={`/projects/${url}`} className="project-slug">
          <h3>{title || "default title"}</h3>
        </Link>
        <p className="project-desc">{description}</p>
        <div className="project-stack">
          {stack.map(item => {
            return <span key={item.id}>{item.title}</span>
          })}
        </div>
        <div className="project-links">
          <a href={github} target="_blank">
            <FaGithubSquare className="project-icon" />
          </a>
          <a href={url}target="_blank">
            {title === "web-generate" || title === "react-searchify" ? <FaNpm className="project-icon" /> : <FaShareSquare className="project-icon" />}
          </a>
        </div>
      </div>
    </article>
  )
}

// Project.propTypes = {
//   title: PropTypes.string.isRequired,
//   github: PropTypes.string.isRequired,
//   url: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   image: PropTypes.object.isRequired,
//   stack: PropTypes.arrayOf(PropTypes.object).isRequired,
// }

export default Project