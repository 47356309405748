import React from "react"
import { FaCode, FaSketch, FaAndroid } from "react-icons/fa"
const services = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Software Engineer",
    text: `I like solving business problem by engineering robust and proficient software`,
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: "Tech Enthusiast",
    text: `I'm passionate to learn more about emerging technologies , currently I'm exploring the sever-side-rendering prospects in ReactJS by means of frameworks like Gatsby and NextJS`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "Problem Solver",
    text: `I love solving algorithmic questions thereby improvising on my software engineerig skills a notch further`,
  },
]

export default services
